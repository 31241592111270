<template>



  <el-row>
    <el-col :span="22">
    </el-col>
    <el-col :span="2">
      <div class="grid-content ep-bg-purple-dark" />
      <el-button type="success" @click="add">添加</el-button>
    </el-col>
  </el-row>
  <el-table :data="data" stripe style="width: 100%">
    <el-table-column prop="id" label="ID" align="center" />
    <el-table-column prop="username" label="用户名" align="center" />
    <el-table-column prop="password" label="密码" show-overflow-tooltip>
      <template #default="scope">
        <el-input v-model="scope.row.password" style="box-shadow:none" type="password" disabled show-password />
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="操作" width="200" align="center">
      <template #default="scope">
        <el-button type="primary" @click="handleEdit(scope.row)">修改</el-button>
        <el-button type="danger" @click="handleRemove(scope.row.id)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>

  <div class="pageClass">
    <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrent" />
  </div>

  <el-dialog v-model="dialogVisible" :title="title" width="500" :before-close="handleClose" :show-close="false">
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="ruleForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import * as axios from '@/api/sysUser'
import { SHA256 } from 'crypto-js';
import { ElMessage, ElMessageBox } from 'element-plus';

export default {
  data() {
    var checkUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('用户名不能为空'));
      }
      setTimeout(() => {
        axios.checkUsername({
          username: value,
          id: this.ruleForm.id
        }).then(resp => {
          console.log(resp, "resp...")
          callback();
        }).catch(err => {
          console.log(err, "err..")
          return callback(new Error(err.desc))
        })
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };

    return {
      params: {
        current: 1,
        size: 10
      },
      data: [],
      total: 0,
      dialogVisible: false,
      ruleForm: {
        password: '',
        username: ''
      },
      rules: {
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        username: [
          { validator: checkUsername, trigger: 'blur' }
        ]
      },
      title: '添加'
    }
  },
  created() {
    this.getData()
  },
  methods: {
    handleCurrent(current) {
      console.log(current)
      this.params.current = current
      this.getData();
    },
    handleRemove(id) {
      ElMessageBox.confirm(
        '确认删除?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {

          console.log("a...")
          axios.remove({ id }).then(resp => {
            console.log(resp, 'resp...')
            if (resp) {
              ElMessage({
                type: 'success',
                message: '删除成功',
              })
              this.getData()
            } else {
              ElMessage({
                type: 'error',
                message: '删除失败',
              })
            }

          }).catch(e => {
            console.log(e, "e...")
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '取消删除',
          })
        })
    },
    isSHA256Hash(str) {
      // 检查字符串长度是否为64
      if (str.length !== 64) return false;
      // 检查字符串是否只包含十六进制字符
      const hexPattern = /^[0-9a-fA-F]{64}$/;
      return hexPattern.test(str);
    },
    ensureSHA256Hash(str) {
      // 如果字符串不是SHA-256哈希值，则计算其SHA-256哈希值
      if (!this.isSHA256Hash(str)) {
        str = SHA256(str).toString();
      }
      return str;
    },
    handleEdit(row) {
      this.ruleForm = row;
      this.title = '修改'
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let password = this.ensureSHA256Hash(this.ruleForm.password)
          let body = {
            "username": this.ruleForm.username,
            "password": password,
            "id": this.ruleForm.id
          }
          if (this.ruleForm.id) {
            axios.update(body).then(resp => {
              console.log(resp, 'resp.')
              ElMessage({
                "message": "操作成功",
                type: 'success'
              })
              this.getData()
              this.resetForm(formName)
              this.dialogVisible = false
            }).catch(e => {
              console.log(e)
            })
          } else {
            axios.save(body).then(resp => {
              console.log(resp, 'resp.')
              ElMessage({
                "message": "操作成功",
                type: 'success'
              })
              this.getData()
              this.resetForm(formName)
              this.dialogVisible = false
            }).catch(e => {
              console.log(e)
            })
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm = {}
      this.title = '添加'
      this.dialogVisible = false
      this.getData();
    },
    add() {
      this.dialogVisible = true
    },
    getData() {
      axios.page(this.params).then(resp => {
        this.data = resp.data
        this.total = resp.total
      })
    },

  }
}
</script>

<style scoped>
.pageClass {
  display: grid;
    justify-content: center;
    align-content: end;
    height: 20vh;
}
</style>