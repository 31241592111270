<template>
  <div class="add-blog">
    <el-card class="add-card">
      <el-form :model="form" label-width="100px">
        <el-form-item label="标题">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-input v-if="inputVisible" ref="inputRef" v-model="inputValue" @keyup.enter="handleInputConfirm" @blur="handleInputConfirm" />
          <el-tag v-for="tag in dynamicTags" :key="tag" closable @close="handleClose(tag)" style="margin-left: 3PX;">
            {{ tag }}
          </el-tag>
          <el-button v-if="!inputVisible" @click="showInput" style="margin-left: 5PX;">+ 新增分类</el-button>
        </el-form-item>
        <el-form-item label="封面图">
          <el-input v-model="form.picture" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <div style="border: 1px solid #ccc">
            <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
            <Editor style="height: 500px; overflow-y: hidden" v-model="form.content" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" @onChange="handleChange" @onDestroyed="handleDestroyed" @onFocus="handleFocus" @onBlur="handleBlur" @customAlert="customAlert" />
          </div>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="action-buttons">
      <el-button type="primary" @click="saveBlog">发布</el-button>
      <el-button @click="cancel">取消</el-button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, shallowRef, onBeforeUnmount, nextTick } from 'vue';
import { ElMessage, ElMessageBox, UploadProps, UploadUserFile } from 'element-plus';
import { addBlog, updateBlog, getBlogById } from '@/api';
import '@wangeditor/editor/dist/css/style.css'; // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { Plus } from '@element-plus/icons-vue';
import router from '@/router';

export default {
  name: 'AddBlog',
  components: { Editor, Toolbar },
  setup() {
    const inputValue = ref('');
    const dynamicTags = ref(['Tag 1', 'Tag 2', 'Tag 3']);
    const inputVisible = ref(false);
    const inputRef = ref(null);

    const form = ref({
      id: null,
      title: '',
      content: '',
      picture: '',
      typeList: '',
      views: ''
    });
    const current = ref(1);
    const editorRef = shallowRef();
    const currentRoute = router.currentRoute.value;
    const toolbarConfig = {
      // 工具栏配置
    };
    const editorConfig = {
      placeholder: '请输入内容...',
      // 其他配置
      MENU_CONF: {}
    };

    editorConfig.MENU_CONF['uploadVideo'] = {
      // 上传视频的配置
      server: '/manager/wangEditor/upload',
      fieldName: 'files',
      maxFileSize: 500 * 1024 * 1024, // 500M
      maxNumberOfFiles: 3,
      allowedFileTypes: ['video/*'],
      headers: {
        token: sessionStorage.getItem('token')
      },
      withCredentials: true,
      timeout: 60 * 1000, // 30 秒
    };

    editorConfig.MENU_CONF['uploadImage'] = {
      // 上传图片的配置
      server: '/manager/wangEditor/upload',
      fieldName: 'files',
      maxFileSize: 100 * 1024 * 1024, // 100M
      maxNumberOfFiles: 10,
      allowedFileTypes: ['image/*'],
      headers: {
        token: sessionStorage.getItem('token')
      },
      withCredentials: true,
      timeout: 30 * 1000, // 30 秒
    };

    const saveBlog = async () => {
      try {
        form.value.typeList= dynamicTags
        if (form.value.id) {
          await updateBlog(form.value);
          ElMessage.success('更新成功');
        } else {
          await addBlog(form.value);
          ElMessage.success('添加成功');
        }
        router.push('/manager/blogs');
      } catch (error) {
        console.log(error);
      }
    };

    const cancel = () => {
      router.push({ path: '/manager/blogs', query: { current: current.value } });
    };

    const handleCreated = (editor) => {
      editorRef.value = editor; // 记录 editor 实例，重要！
    };

    const handleChange = (editor) => {
      console.log('change:', editor.getHtml());
    };

    const handleDestroyed = (editor) => {
      console.log('destroyed', editor);
    };

    const handleFocus = (editor) => {
      // console.log('focus', editor.getHtml());
    };

    const handleBlur = (editor) => {
      //console.log('blur', editor.getHtml());
    };

    const customAlert = (info, type) => {
      // alert(`【自定义提示】${type} - ${info}`);
    };

    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.destroy();
    });

    if (currentRoute.query.id) {
      form.value.id = currentRoute.query.id;
      getBlogById(form.value.id).then(resp => {
        form.value = resp;
        dynamicTags.value=resp.typeList?resp.typeList:[]

      });
    }

    const handleClose = (tag) => {
      dynamicTags.value.splice(dynamicTags.value.indexOf(tag), 1);
    };

    const showInput = () => {
      inputVisible.value = true;
      nextTick(() => {
        inputRef.value.focus();
      });
    };

    const handleInputConfirm = () => {
      if (inputValue.value) {
        dynamicTags.value.push(inputValue.value);
      }
      inputVisible.value = false;
      inputValue.value = '';
    };

    return {
      handleClose,
      showInput,
      handleInputConfirm,
      form,
      editorRef,
      toolbarConfig,
      editorConfig,
      saveBlog,
      cancel,
      handleCreated,
      handleChange,
      handleDestroyed,
      handleFocus,
      handleBlur,
      customAlert,
      current,
      inputValue,
      dynamicTags,
      inputVisible,
      inputRef
    };
  }
};
</script>

<style scoped>
.add-blog {
  display: flex;
  flex-direction: column;
  height: 90vh;
  padding: 20px;
}

.add-card {
  flex-grow: 1;
  margin-bottom: 20px;
}

.action-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}
</style>