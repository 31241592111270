<template>
    <div class="dashboard">
      <h2>仪表盘</h2>
      <p>欢迎来到后台管理系统！</p>
    </div>
  </template>

<script>
export default {
  name: 'Dashboard'
}
</script>

  <style scoped>
  .dashboard {
    text-align: center;
  }
  </style>
