<template>
  <div class="home">
    <!-- 导航栏 -->
    <el-header class="header">
      <el-menu :router="true" mode="horizontal" background-color="#545c64" text-color="#fff"
        active-text-color="#ffd04b">
        <el-menu-item index="/">首页</el-menu-item>
        <!-- <el-menu-item index="/about">关于我们</el-menu-item>
        <el-menu-item index="/contact">联系我们</el-menu-item>
        <el-submenu index="more">
          <template slot="title">更多</template>
<el-menu-item index="/more/service">服务</el-menu-item>
<el-menu-item index="/more/help">帮助中心</el-menu-item>
</el-submenu> -->
      </el-menu>
    </el-header>
    <!-- 主体部分 -->
    <el-main class="main">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18" style="height: 100%;">
          <!-- 博客列表 -->
          <transition-group name="slide-fade" tag="div" class="blog-list">
            <el-card v-if="totalBlogs != 0" v-for="(blog, index) in blogs" :key="index" class="blog-item"
              @click.native="goToBlog(blog.id)">
              <div class="blog-content" style="width: 100%;">
                <div class="blog-details">
                  <h2>{{ blog.title }}</h2>
                  <div class="meta-info">
                    <span>发布日期: {{ blog.createTime }}</span>
                    <span>浏览量: {{ blog.views }}</span>
                  </div>
                </div>
              </div>
            </el-card>
            <el-card v-else class="blog-item" style="text-align: center;">
              数据为空
            </el-card>
          </transition-group>
          <div class="pClass">
            <!-- 分页组件 -->
            <el-pagination layout="prev, pager, next" :total="totalBlogs" :page-size="pageSize"
              @current-change="handlePageChange" />
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" style="height: 100%;">
          <!-- 右侧栏 -->
          <div class="right-sidebar" style="height: 100%;">
            <!-- 搜索部分 -->
            <div class="search-bar" style="margin-bottom: 20px;">
              <el-input v-model="searchQuery" placeholder="请输入搜索关键词" style="width: 70%; margin-top: 10px;"></el-input>
              <el-button type="primary" @click="performSearch"
                style="width: 10%; margin-top: 10px;margin-left: 2.5%;">搜索</el-button>
              <el-button type="warning" @click="resetSearch"
                style="width: 10%; margin-top: 10px;margin-left: 2.5%;">重置</el-button>
            </div>
            <!-- 文章标签展示 -->
            <div class="tag-section">
              <h3 style="text-align: center">分类列表</h3>
                <el-check-tag v-for="type in blogTypeList" :key="type" style="margin-right: 10px; margin-bottom: 10px;"
                  size="large" :checked="selectedType === type" @change="typeChange(type)">
                  {{ type }}
                </el-check-tag>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-main>

    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { blogsApi, qtApi } from '@/api/index';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Footer
  },
  setup() {
    const router = useRouter();
    const blogs = ref([]);
    const searchQuery = ref('');
    const params = ref({
      currentPage: 1,
      pageSize: 5
    });
    const totalBlogs = ref(0); // 总博客数量
    const pageSize = ref(5); // 每页显示的博客数量
    const isMoreMenuVisible = ref(false);
    const blogTypeList = ref([]); // 文章分类
    const selectedType = ref(null);

    const handlePageChange = (currentPage) => {
      params.value.currentPage = currentPage;
      fetchBlogs();
    };
    // 判断某个类型是否已禁用
    const isDisabled = (type) => {
      return disabledTypes.value.includes(type);
    };
    // 处理标签选中变化
    const typeChange = (type) => {
      if (selectedType.value === type) {
        // 如果已经选中，则取消选中
        selectedType.value = null;
        params.value.type = null
      } else {
        // 选中新类型
        selectedType.value = type;
        params.value.type = type
      }
      fetchBlogs()
    };
    const goToBlog = (id) => {
      router.push({ name: 'BlogDetail', params: { id } });
    };

    const fetchBlogs = async () => {
      try {
        const resp = await blogsApi(params.value);
        blogs.value = resp.data; // 假设后端返回的数据结构为 { data: { list: [] } }
        totalBlogs.value = resp.total; // 假设后端返回的总记录数为 data.total
      } catch (error) {
        console.error('Failed to fetch blogs:', error);
      }
    };

    const performSearch = () => {
      // 这里可以添加搜索逻辑，例如过滤博客列表
      console.log('Performing search with query:', searchQuery.value);
      params.value.currentPage = 1;
      params.value.title = searchQuery.value;
      fetchBlogs();
    };


    const filteredBlogs = computed(() => {
      console.log(performSearch)
      fetchBlogs();
    });
    const watchSearchQuery = watch((p1, p2) => {
      console.log(p1, "p1")
      console.log(p2, "p2")
    })
    const toggleMoreMenu = () => {
      isMoreMenuVisible.value = !isMoreMenuVisible.value;
    };
    const fetchBlogTypeList = () => {
      qtApi().then(resp => {
        if (resp) {
          blogTypeList.value = (resp.typeList);
        }
      }).catch(e => {
        console.log(e)
      })

    }
    // 页面加载时自动触发数据请求
    onMounted(() => {
      fetchBlogs();
      fetchBlogTypeList();
    });
    const resetSearch = () => {
      params.value.currentPage = 1;
      params.value.title = null;
      params.value.type = null;
      searchQuery.value = ''
      selectedType.value = null;
      fetchBlogs();
    }
    return {
      params,
      blogs,
      totalBlogs,
      pageSize,
      handlePageChange,
      goToBlog,
      isMoreMenuVisible,
      toggleMoreMenu,
      searchQuery,
      filteredBlogs,
      performSearch,
      blogTypeList,
      watchSearchQuery,
      resetSearch,
      fetchBlogTypeList,
      typeChange,
      isDisabled,
      selectedType,

    };
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
  background: linear-gradient(135deg, #e0e0e0, #ffffff);
}

.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 60px;
  /* 固定头部高度 */
}

.nav-menu {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  /* 可选：限制最大宽度 */
  margin: 0 auto;
}

.nav-menu .el-menu-item {
  height: 60px;
  line-height: 60px;
  flex-grow: 1;
  /* 确保菜单项均匀分布 */
}

.main {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.left-sidebar,
.right-sidebar {
  padding: 20px;
  overflow-y: auto;
  box-sizing: border-box;
}

.blog-list {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  min-height: 71vh;
}

.blog-item {
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.blog-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-content {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 10px;
}

.blog-details {
  flex: 1;
  box-sizing: border-box;
}

.meta-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.footer {
  text-align: center;
  background-color: #f9f9f9;
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
  padding: 20px 0;
  height: 100%;
}

p {
  margin: 5px;
}

.pClass {
  display: grid;
  justify-content: center;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

@media (max-width: 768px) {
  .el-row {
    flex-direction: column;
  }

  .left-sidebar,
  .right-sidebar {
    display: block;
  }

  .blog-list {
    width: 100%;
  }

  .pClass {
    position: static;
    margin: 20px 0;
  }

  .header .el-menu {
    display: flex;
    justify-content: left;
    width: 100%;
  }

  .header .el-menu-item {
    height: 60px;
    line-height: 60px;
  }

  .footer {
    padding: 10px 0;
  }

  .search-bar {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 20px;

  }

  .search-bar .el-input,
  .search-bar .el-button {
    width: 100%;
  }

  .right-sidebar {
    order: 1;
    /* 将右侧栏移到博客列表上方 */
  }

  .blog-list {
    order: 2;
    /* 将博客列表移到右侧栏下方 */
  }
}

h2 {
  margin: 0px;
  font-size: 18px;
}

.el-card__body {
  padding: 10px;
}

/* 动画效果 */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.el-header {
  padding: 0px;
}
</style>