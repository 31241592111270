<template>
  <div class="blogs">
    <el-card class="box-card hStyle">
      <template #header>
        <div class="card-header">
          <span>博客管理</span>
          <el-button type="primary" @click="openAddDialog">添加博客</el-button>
        </div>
      </template>
      <el-table :data="blogs" style="width: 100%" :show-overflow-tooltip="true" border > 
        <el-table-column prop="title" label="标题" align="center"></el-table-column>
        <el-table-column prop="picture" label="封面图" width="180" align="center">
          <template #default="scope">
            <img v-if="scope.row.picture" :src="scope.row.picture.split(',')[0]" alt="封面图"
              style="width: 50px; height: 50px;">
          </template>
        </el-table-column>
        <el-table-column prop="type" label="分类" width="120" align="center">
          <template #default="scope">
            <el-tag v-for="tag in scope.row.typeList" :key="tag" >
            {{ tag }}
          </el-tag>
          </template>
     
        </el-table-column>
        <el-table-column prop="views" label="浏览量" width="80" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180" align="center">
          <template #default="scope">
            {{ formatDate(scope.row.createTime) }}
          </template>
        </el-table-column>
     
        <el-table-column prop="createBy" label="创建人" width="100" align="center"></el-table-column>
        <el-table-column prop="updateTime" label="修改时间" width="180" align="center"></el-table-column>
        <el-table-column prop="updateBy" label="修改人" width="100" align="center"></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button size="mini" @click="editBlog(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteBlog(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageClass">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total"></el-pagination>
      </div>

    </el-card>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getBlogList, removeBlog } from '@/api';

export default {
  name: 'Blogs',
  setup() {
    const blogs = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const total = ref(0);
    const router = useRouter();

    const fetchBlogs = async () => {
      try {
        let current = router.currentRoute.value.query.current;
        console.log(current, "a.......")
        if (current && current != 1) {
          currentPage.value = Number(current)
          router.currentRoute.value.query.current = 1;
        }
        const params = {
          currentPage: currentPage.value,
          pageSize: pageSize.value
        };
        console.log(params, "params..")
        const response = await getBlogList(params);
        blogs.value = response.data;
        total.value = response.total;
      } catch (error) {
        ElMessage.error('获取博客列表失败');
      }
    };

    const openAddDialog = () => {
      console.log(router, "router....")
      router.push('/manager/add-blog');
    };

    const editBlog = (blog) => {
      console.log(router, "router...")
      router.push({ path: '/manager/edit-blog', query: { id: blog.id, current: currentPage.value } });
    };

    const deleteBlog = (id) => {
      ElMessageBox.confirm('确定要删除该博客吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(id, "id...")
        removeBlog(id).then(resp => {
          console.log(resp, "resp...")
          ElMessage.success('删除成功');
          fetchBlogs();
        })

          .catch(error => {
            ElMessage.error('删除失败');
          })
      }).catch(() => {
        ElMessage.info('已取消删除');
      });
    };

    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
      fetchBlogs();
    };

    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
      fetchBlogs();
    };

    const formatDate = (date) => {
      return date ? new Date(date).toLocaleString() : '';
    };

    onMounted(() => {

      fetchBlogs();
    });

    return {
      blogs,
      currentPage,
      pageSize,
      total,
      openAddDialog,
      editBlog,
      deleteBlog,
      handleSizeChange,
      handleCurrentChange,
      formatDate
    };
  }
};
</script>

<style scoped>
.blogs {
  padding: 20px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-table {
  margin-top: 20px;
  min-height: 100%;
}

.el-pagination {
  margin-top: 20px;
  text-align: center;
}

.hStyle {
  min-height: 84vh;
}

.pageClass {
  display: grid;
    justify-content: center;
    position: fixed;
    bottom: 10vh;
    left: 0;
    right: 0;
    z-index: 0;
}
</style>