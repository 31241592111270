import { createRouter, createWebHistory } from 'vue-router';
import ManagerLayout from '../views/manager/ManagerLayout.vue';
import ManagerLogin from '../views/manager/ManagerLogin.vue';
import Dashboard from '../views/manager/Dashboard.vue';
import Users from '../views/manager/Users.vue';
import Blogs from '../views/manager/Blogs.vue';
import AddBlog from '../views/manager/AddBlog.vue';
import Index from '@/views/Index.vue';
import BlogDetail from '@/views/BlogDetail.vue';

const routes = [
  {
    path: '/manager',
    component: ManagerLayout,
    children: [
      { path: '', component: Dashboard, meta: { requiresAuth: true } },
      { path: 'users', component: Users, meta: { requiresAuth: true } },
      { path: 'blogs', component: Blogs, meta: { requiresAuth: true } },
      { path: 'add-blog', component: AddBlog, meta: { requiresAuth: true } },
      { path: 'edit-blog', component: AddBlog, meta: { requiresAuth: true } },
    ],
  },
  {
    path: '/manager/login',
    component: ManagerLogin,
    meta: { requiresAuth: false },
  },
  {
    path: '/',
    component: Index,
  },
  {
    path: '/BlogDetail/:id',
    name: 'BlogDetail',
    component: BlogDetail,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta.requiresAuth;
  const token = sessionStorage.getItem('token');

  if (requiresAuth && !token) {
    next('/manager/login');
  } else {
    next();
  }
});

export default router;