<template>
  <div class="login">
    <el-card class="box-card">
      <h2>管理员登录</h2>
      <el-form @submit.prevent="handleLogin" label-position="top">
        <el-form-item label="用户名">
          <el-input v-model="username" required></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="password" required></el-input>
        </el-form-item>
        <el-button type="primary" native-type="submit" class="submit-btn">登录</el-button>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { login, handleApiError } from '@/api';
import CryptoJS from 'crypto-js'; // 引入 crypto-js

export default {
  setup() {
    const router = useRouter();
    const username = ref('');
    const password = ref('');

    const handleLogin = () => {
      console.log("login...");
      // 对密码进行 SHA-256 加密
      const encryptedPassword = CryptoJS.SHA256(password.value).toString(CryptoJS.enc.Hex);

      const params = {
        username: username.value,
        password: encryptedPassword
      };

      console.log('Sending login request with params:', params);

      login(params)
        .then(respData => {
          console.log(respData, "resp...")
          sessionStorage.setItem('token', respData)
          router.push('/manager');
        })
        .catch(error => {
          // 处理错误
          console.error('Login failed:', error);
          handleApiError(error);
        });
    };

    return {
      username,
      password,
      handleLogin
    };
  }
};
</script>

<style scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.box-card {
  width: 300px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.submit-btn {
  width: 100%;
}
</style>