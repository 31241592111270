<template>
    <el-footer class="footer">
      <p>&copy; 人人博客 • Powered by 2024 renren. </p>
      <p>♥ Theme Amaze by spiritree & ziHao.Wang</p>
      <p>备案号: ICP 豫ICP备2024065191号</p>
    </el-footer>
  </template>
  
  <script>
  export default {
    name: 'Footer'
  };
  </script>
  
  <style scoped>
  .footer {
    text-align: center;
    background-color: #f9f9f9;
    flex-shrink: 0;
    box-sizing: border-box;
    position: relative;
    height: 100%;
  }
  
  p {
    margin: 5px;
  }
  </style>