import axios from "../utils/axios";

// 导出常用的 API 接口
export const login = (params) => axios.post("/manager/login", params);
export const getUserInfo = () => axios.get("/manager/userInfo");
export const getDashboardData = () => axios.get("/manager/dashboard");
// 添加更多 API 接口...
// 添加更多 API 接口...
export const getBlogList = (params) => axios.get("/manager/blogs", { params });
export const addBlog = (data) => axios.post("/manager/blog", data);
export const updateBlog = (data) => axios.put("/manager/blog", data);
export const removeBlog = (id) => axios.delete("/manager/blog?id=" + id);


export const getBlogById = (params) => axios.get("/manager/blog?id=" + params);

//用户接口管理
export const blogsApi = (params) => axios.get("/index/blogs", { params });
export const qb = (params) => axios.get("/index/qb", { params });
export const qtApi = () => axios.get("/index/qt");
// 示例错误处理函数
export const handleApiError = (error) => {
  if (error.response) {
    const { code, desc } = error.response.data;
    console.error(`API Error [${code}]: ${desc}`);
  } else {
    console.error("Network Error:", error.message);
  }
};
