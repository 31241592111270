<template>
  <div class="home">
    <!-- 导航栏 -->
    <el-header class="header">
      <el-menu :router="true" mode="horizontal" background-color="#545c64" text-color="#fff"
        active-text-color="#ffd04b">
        <el-menu-item index="/">首页</el-menu-item>
        <!-- <el-menu-item index="/about">关于我们</el-menu-item>
        <el-menu-item index="/contact">联系我们</el-menu-item>
        <el-submenu index="more">
          <template slot="title">更多</template>
          <el-menu-item index="/more/service">服务</el-menu-item>
          <el-menu-item index="/more/help">帮助中心</el-menu-item>
        </el-submenu> -->
      </el-menu>
    </el-header>

    <!-- 主体部分 -->
    <el-main class="main">
      <el-row :gutter="20" > <!-- 减去头部和底部高度 -->
     
        <el-col :xs="24" :sm="24" :md="18" :lg="24" :xl="18" style="height: 100%;">
          <!-- 博客列表 -->
          <div class="blog-list" style=" overflow-y: auto; overflow-x: hidden;">
            <div> 
              <h1 style="text-align: center;">
                <el-text class="mx-1" size="large" type="primary" style="font-size:1em">{{ blog.title }}</el-text>
              </h1>
        
            </div>
            <Editor v-model="blog.content" :defaultConfig="editorConfig" />
          </div>
        </el-col>

      </el-row>
    </el-main>

    <!-- 底部 -->
    <el-footer class="footer">
      <p>&copy; 人人博客 • Powered by 2024 renren. </p>
      <span>备案号: ICP 豫ICP备2024065191号 ♥ Theme Amaze by spiritree & ziHao.Wang </span>
    </el-footer>
  </div>
</template>

<script>
import { ref, onMounted, shallowRef, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { qb } from '@/api/index';
import { Editor } from '@wangeditor/editor-for-vue';
import '@wangeditor/editor/dist/css/style.css'; // 引入 css
export default {
  components: { Editor },
  setup() {

    const router = useRouter();
    const blog = ref({});
    const params = ref({
      id: router.currentRoute.value.params.id,
    });


    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef();
    const currentRoute = router.currentRoute.value

    const editorConfig = {
      placeholder: '请输入内容...',
      // 其他配置
      MENU_CONF: {},
    };
    editorConfig.readOnly = true
    editorConfig.scroll = false

    // 页面加载时自动触发数据请求
    onMounted(() => {
      fetchBlogs();
    });


    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.destroy();
    });


    const fetchBlogs = async () => {
      try {
        console.log(router, "params...")
        const resp = await qb(params.value);
        console.log(resp, "resp...")
        blog.value = resp; // 假设后端返回的数据结构为 { data: { list: [] } }

        console.log(blog.value.title, "blog...")
      } catch (error) {
        console.error('Failed to fetch blogs:', error);
      }
    };

    return {
      blog,
      params,
      editorConfig
    };
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* 防止多余的滚动条 */
}

.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 60px;
  /* 固定头部高度 */
}

.main {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  /* 确保 padding 不增加高度 */
  /* overflow: hidden; */
  /* 防止多余的滚动条 */
  display: flex;
  flex-direction: column;
  min-height: 93vh
}

.left-sidebar,
.right-sidebar {
  padding: 20px;
  /* background-color: #f9f9f9; */
  overflow-y: auto;
  /* 如果内容过多，允许内部滚动 */
  box-sizing: border-box;
  /* 确保 padding 不增加高度 */
}

.blog-list {
  flex: 1;
  overflow-y: auto;
  /* 如果内容过多，允许内部滚动 */
  overflow-x: hidden;
  /* 隐藏水平滚动条 */
  box-sizing: border-box;
  /* 确保 padding 不增加高度 */
  padding-left: 15%;
  padding-right: 15%;
}
/* 媒体查询，针对手机端（例如屏幕宽度小于等于 768px） */
@media (max-width: 768px) {
  .blog-list {
    padding-left: 0; /* 移除左边 padding */
    padding-right: 0; /* 移除右边 padding */
  }
}
.blog-item {
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* 确保 padding 不增加高度 */
}

.blog-content {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  /* 防止换行 */
  box-sizing: border-box;
  /* 确保 padding 不增加高度 */
}

.blog-details {
  flex: 1;
  padding: 10px;
  flex-shrink: 0;
  /* 防止缩小 */
  box-sizing: border-box;
  /* 确保 padding 不增加高度 */
}

.blog-image {
  width: 150px;
  /* 图片宽度 */
  height: auto;
  margin-left: 10px;
  flex-shrink: 0;
  /* 防止缩小 */
  box-sizing: border-box;
  /* 确保 padding 不增加高度 */
}

.footer {
  text-align: center;
  background-color: #f9f9f9;
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
  padding: 20px 0;
  height: 100%;
}


.pClass {
  text-align: center;
  width: 100%;

}
.el-header{
  padding: 0px;
}
</style>