import axios from "axios";
import { ElMessage, ElLoading } from "element-plus";

const baseURL_DEV = "http://localhost:10000";
const baseURL_PRO = "https://www.wzhblog.fun/blog";
// 创建 Axios 实例
const instance = axios.create({
  baseURL: baseURL_PRO, // 后台服务器地址
  timeout: 5000, // 请求超时时间 单位:毫秒
  headers: {
    "Content-Type": "application/json",
  },
});

let loadingInstance;

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 显示加载指示器
    // loadingInstance = ElLoading.service({
    //   lock: true,
    //   text: '正在加载中，请稍等...',
    //   background: 'rgba(0, 0, 0, 0.7)',
    // });

    // 在发送请求之前做些什么
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers.token = token; // 使用 token 作为键名
    }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 关闭加载指示器
    // if (loadingInstance) {
    //   loadingInstance.close();
    // }

    // 对响应数据做点什么
    const { code, desc, data } = response.data;
    if (code === "00000") {
      return data;
    } else {
      ElMessage.error("操作失败,原因: " + desc);
      return Promise.reject({ code, desc });
    }
  },
  (error) => {
    // 关闭加载指示器
    // if (loadingInstance) {
    //   loadingInstance.close();
    // }

    // 对响应错误做点什么
    if (error.response) {
      const { code, desc } = error.response.data;
      return Promise.reject({ code, desc });
    } else {
      ElMessage.error('网络请求异常，请检查您的网络连接');
      return Promise.reject(error);
    }
  }
);

// 导出 Axios 实例
export default instance;