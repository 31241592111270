<template>
  <div class="common-layout">
    <el-container>
      <el-header >头部</el-header>
      <el-container>
        <el-aside width="200px">
          <aside class="sidebar">
            <ul>
              <li><router-link to="/manager">仪表盘</router-link></li>
              <li><router-link to="/manager/users">用户管理</router-link></li>
              <li><router-link to="/manager/blogs">博客管理</router-link></li>
              <!-- 其他菜单项 -->
            </ul>
          </aside>
        </el-aside>
        <el-container>
          <el-main class="cMain">内容区域
            <el-config-provider>
              <router-view></router-view>
            </el-config-provider>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>


<script>
export default {
  name: 'ManagerLayout',

}
</script>

<style scoped>
.manager-layout {
  display: -webkit-box;
  min-height: 98vh;
}

.sidebar {
  width: 10rem;
  background-color: #f4f4f4;
  padding: 10px;
  text-align: center;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  text-decoration: none;
  color: #333;
}
.el-header{
  min-height: 5vh;;
  max-height: 5vh;
}
.cMain{
}
</style>
